import { Company, CompanyDraft, Currency, Invoice, Vendor } from '@prisma/client'
import { pick } from 'remeda'
import { routes as codatRoutes } from 'server/modules/codat-adapter/module-routes'
import { nextApiRoutes as esignaturesAdapterApiRoutes } from 'server/modules/esignatures-adapter/module-routes'
import { Invite } from 'entities/invite'
import { Term } from 'entities/term'
import { addQueryParams } from 'lib/url'
import { AddInvoiceStepId } from 'containers/add-invoice/data/addInvoiceSteps'
import { QueryParams as AssistanceRequestQueryParams } from 'containers/compliance/assistance-request/hooks/useQueryParams'
import { QueryParams as ComplianceQueryParams } from 'containers/compliance/checklist/useQueryParams'
import { SHOW_CALCULATOR_QUERY_PARAM } from 'containers/dashboard/hooks/useCalculatorPopup'
import { DashboardAction } from 'containers/dashboard/hooks/useQueryParams'
import { OnboardingStepId } from 'containers/onboarding/data/onBoardingSteps'

export type AddInvoiceOrigin = 'dashboard' | 'invoices' | 'calculator-popup'
export type InvoiceId = Invoice['id']
export type InvoiceStatus = Invoice['status']
export type CompanyId = Company['id']
export type CompanyDraftId = CompanyDraft['id']
export type VendorId = Vendor['id']

export const routes = {
    onboarding: {
        step: (step: OnboardingStepId) => `/onboarding/${step}`,
        initial: () => '/onboarding',
        prepareDashboard: () => {
            return '/onboarding/prepare-dashboard'
        },
        personalDetails: () => '/onboarding/personal-details',
    },
    invoices: {
        all: ({ invoiceId }: { invoiceId?: InvoiceId } = {}) => {
            return addQueryParams('/invoices', { invoiceId })
        },
        add: {
            step: ({
                step,
                params,
            }: {
                step: AddInvoiceStepId
                params?: {
                    invoiceId?: InvoiceId
                    id?: string
                    from?: AddInvoiceOrigin
                    invoiceAmount?: number
                    invoiceCurrency?: Currency
                    invoiceDueDate?: string
                    selectedTerm?: Term
                    conversionRateSnapshotId?: string
                }
            }) => addQueryParams(`/invoices/add/${step}`, params),
        },
        lendingContract: ({ invoiceId }: { invoiceId: InvoiceId }) =>
            `/invoices/${invoiceId}/lending-contract`,
        csv: ({ companyId }: { companyId: CompanyId }) =>
            addQueryParams(`/api/invoices/csv`, { companyId }),
    },
    dashboard: (
        params?:
            | {
                  action: DashboardAction['requestCredit']
              }
            | {
                  action: DashboardAction['showInvoiceComplete']
                  invoiceId: string
              }
            | {
                  [SHOW_CALCULATOR_QUERY_PARAM]: true
              },
    ) => {
        return addQueryParams('/dashboard', params)
    },
    api: {
        uploadInvoice: () => '/api/invoices/upload',
        // TODO [231]: should we add another handler for failures to log them? not sure
        esignatures: esignaturesAdapterApiRoutes,
    },
    admin: {
        // the route to check you're logged in as an admin
        // could be killed if we have more suitable pages to check that
        home: () => '/admin',
        test: () => '/admin/test',
        logout: () => '/admin/logout',
        login: (queryParams?: { warning?: string }) =>
            addQueryParams('/admin/login', queryParams),
        invoices: {
            all: (queryParams?: {
                company?: Pick<Company, 'id' | 'name'>
                statuses?: InvoiceStatus[]
            }) => {
                const entity = queryParams?.company
                    ? {
                          entityIdentifierType: 'company',
                          entityIdentifierId: queryParams?.company.id,
                          entityIdentifierLabel: queryParams?.company.name,
                      }
                    : {}
                return addQueryParams('/admin/invoices', {
                    ...entity,
                    ...(queryParams && pick(queryParams!, ['statuses'])),
                })
            },
            invoice: ({ invoiceId }: { invoiceId: InvoiceId }) => {
                return `/admin/invoices/${invoiceId}`
            },
        },
        users: {
            all: (queryParams?: { companyId?: CompanyId }) =>
                addQueryParams('/admin/users', queryParams),
        },
        companies: {
            all: () => '/admin/companies',
            onboarding: () => '/admin/companies/onboarding',
            company: ({ companyId }: { companyId: CompanyId }) => {
                return `/admin/companies/${companyId}`
            },
            companyDraft: ({ companyDraftId }: { companyDraftId: CompanyDraftId }) => {
                return `/admin/companies/onboarding/${companyDraftId}`
            },
        },
        vendors: {
            all: () => '/admin/vendors',
            vendor: ({ vendorId }: { vendorId: VendorId }) => {
                return `/admin/vendors/${vendorId}`
            },
        },
        credit: () => '/admin/credit',
        model: () => '/admin/model',
        discounts: () => '/admin/discounts',
        financialHistory: () => '/admin/financial-history',
        availableFunds: () => '/admin/available-funds',
        rates: () => '/admin/rates',
        codat: codatRoutes,
        reports: (report: string) => `/admin/reports/${report}`,
        investors: {
            all: () => '/admin/investors',
            investor: ({
                investorId,
                cycleNumber,
            }: {
                investorId: string
                cycleNumber?: number
            }) => addQueryParams(`/admin/investors/${investorId}`, { cycleNumber }),
            investorCycleReportCSV: (args: { investorId: string; cycleNumber: number }) =>
                addQueryParams(`/api/investor-portal/cycle-report/csv`, args),
        },
        activities: () => '/admin/activities',
    },
    compliance: {
        assistanceRequest: ({ invoiceId }: AssistanceRequestQueryParams) => {
            return addQueryParams('/compliance/assistance-request', { invoiceId })
        },
        checklist: ({ invoiceId, expectComplete }: ComplianceQueryParams = {}) => {
            return addQueryParams(
                invoiceId
                    ? `/compliance/checklist/${invoiceId}`
                    : '/compliance/checklist',
                { expectComplete },
            )
        },
    },
    home: (args?: { invite: Pick<Invite, 'invitedUserType' | 'id'> }) => {
        return addQueryParams(
            '/',
            args?.invite && {
                inviteId: args.invite.id,
                type: args.invite.invitedUserType,
            },
        )
    },
    privacyPolicy: () => 'https://www.kikin.io/privacy-policy',
    termsOfService: () => 'https://kikin.io/terms-of-service',
    // TODO [ISSUE 2025]: should be new terms of service for vendors
    termsOfServiceVendor: () => 'https://kikin.io/terms-of-service',
    kikin: () => 'https://www.kikin.io',
    esgProfile: () => '/esg-profile',
    company: () => '/company',
    discounts: () => '/discounts',
    sendFeedback: () => '/send-feedback',
    support: () => '/support',
    checkEmail: (email: string) => addQueryParams('/check-email', { email }),
    pipedream: {
        submitFeedback: () => 'https://18feb9ecf2dd4770d1a2f2287a93b0b7.m.pipedream.net',
        redirectConfig: () => 'https://eovafvnfn7i2ibu.m.pipedream.net',
    },
    govUK: () => 'http://www.gov.uk',
    calculator: () => '/calculator',
    vendor: {
        details: (args: { vendorId: string; bankAccountId: string }) =>
            addQueryParams(`/vendor/${args.vendorId}`, {
                bankAccountId: args.bankAccountId,
            }),
        bankAccountDetails: (args: { vendorId: string; bankAccountId: string }) =>
            `/vendor/${args.vendorId}/bank-account/${args.bankAccountId}`,
        login: (args: { vendorVerificationRequestId: string }) =>
            `/vendor/verification-request/${args.vendorVerificationRequestId}`,
        verificationSuccessfull: (args: { vendorId: string }) =>
            `/vendor/${args.vendorId}/verification-successful`,
        home: () => '/vendor/home',
    },
    auth: {
        callbacks: {
            companyOwner: () => '/api/auth/success/company-owner',
            vendor: (args: { vendorVerificationRequestId: string }) =>
                addQueryParams('/api/auth/success/vendor', {
                    id: args.vendorVerificationRequestId,
                }),
            all: (args?: { inviteId?: string }) =>
                addQueryParams('/api/auth/success', args),
        },
    },
    investor: {
        home: () => '/investor/home',
    },
}
