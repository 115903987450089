import getConfig from 'next/config'
import { hashQueryKey } from '@tanstack/react-query'
import { httpBatchLink } from '@trpc/client'
import { createTRPCNext } from '@trpc/next'
import { TRPC_ERROR_CODE_KEY } from '@trpc/server/src/rpc/codes'
import type { AppRouter } from 'server/trpc/routes/_app'
import superjson from 'superjson'
import { isClientSide } from 'lib/env'

const config = getConfig()

export function getBaseUrl() {
    if (isClientSide) return ''

    return config.publicRuntimeConfig.appUrlAllEnvs
}
export const trpc = createTRPCNext<AppRouter>({
    config({ ctx }) {
        return {
            transformer: superjson,
            links: [
                httpBatchLink({
                    /**
                     * If you want to use SSR, you need to use the server's full URL
                     * @link https://trpc.io/docs/ssr
                     **/
                    url: `${getBaseUrl()}/api/trpc`,
                }),
            ],
            /**
             * @link https://tanstack.com/query/v4/docs/reference/QueryClient
             **/
            // queryClientConfig: { defaultOptions: { queries: { staleTime: 60 } } },
        }
    },
    /**
     * @link https://trpc.io/docs/ssr
     **/
    ssr: false,
})

export const ERROR_CODES: { [k in string]: TRPC_ERROR_CODE_KEY } = {
    BAD_REQUEST: 'BAD_REQUEST',
    UNAUTHORIZED: 'UNAUTHORIZED',
    FORBIDDEN: 'FORBIDDEN',
    NOT_FOUND: 'NOT_FOUND',
    METHOD_NOT_SUPPORTED: 'METHOD_NOT_SUPPORTED',
    TIMEOUT: 'TIMEOUT',
    CONFLICT: 'CONFLICT',
    PRECONDITION_FAILED: 'PRECONDITION_FAILED',
    PAYLOAD_TOO_LARGE: 'PAYLOAD_TOO_LARGE',
    TOO_MANY_REQUESTS: 'TOO_MANY_REQUESTS',
    CLIENT_CLOSED_REQUEST: 'CLIENT_CLOSED_REQUEST',
    INTERNAL_SERVER_ERROR: 'INTERNAL_SERVER_ERROR',
    UNPROCESSABLE_CONTENT: 'UNPROCESSABLE_CONTENT',
} as const

export const defaultHashQueryKey = hashQueryKey
