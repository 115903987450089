import { ComponentStyleConfig } from '@chakra-ui/react'

export const FormLabel: ComponentStyleConfig = {
    baseStyle: {
        color: 'green.default',
    },
    sizes: {
        md: {
            fontWeight: 400,
            fontSize: '20px',
            lineHeight: '30px',
        },
    },
    defaultProps: {
        size: { base: 'sm', md: 'md' },
    },
}
