import { ComponentStyleConfig } from '@chakra-ui/react'

export const Text: ComponentStyleConfig = {
    baseStyle: {
        color: 'green.default',
    },
    variants: {
        'paragraph-m': {
            fontWeight: 400,
            fontSize: '18px',
            lineHeight: '28px',
        },
        'paragraph-s': {
            fontWeight: 400,
            fontSize: '16px',
            lineHeight: '26px',
        },
        'paragraph-xs': {
            fontWeight: 400,
            fontSize: '14px',
            lineHeight: '22px',
        },
        'caption-m': {
            fontWeight: 500,
            fontSize: '16px',
            lineHeight: '20px',
        },
        'caption-s': {
            fontWeight: 500,
            fontSize: '14px',
            lineHeight: '20px',
        },
    },
    defaultProps: {
        variant: 'paragraph-s',
    },
}
