import { defineStyle, defineStyleConfig } from '@chakra-ui/react'

// base
const baseStyle = defineStyle(({ theme }) => {
    const colors = theme.colors

    return {
        _placeholder: { color: colors.captions.placeholder },
        color: colors.green.default,
    }
})

// sizes
const sizes = {
    lg: defineStyle({
        padding: '19px 24px',
        height: '180px',
        fontSize: '20px',
        lineHeight: '28px',
    }),
    md: defineStyle({
        padding: '14px 24px',
        height: '160px',
        fontSize: '18px',
        lineHeight: '28px',
    }),
    sm: defineStyle({
        padding: '10px',
        height: '120px',
        fontSize: '16px',
        lineHeight: '28px',
    }),
}

// variants
const variants = {
    filled: defineStyle(({ theme }) => {
        const colors = theme.colors

        return {
            border: '1px solid',
            borderRadius: '10px',
            borderColor: colors.bg['green10'],
            backgroundColor: colors.beige.highlighted,
            _hover: {
                borderColor: colors.captions.disabled,
                backgroundColor: colors.beige.highlighted,
            },
            _focusVisible: {
                borderColor: colors.green.default,
                backgroundColor: colors.beige.highlighted,
            },
            _active: {
                borderColor: colors.green.default,
            },
            _readOnly: {
                pointerEvents: 'none',
            },
            _disabled: {
                cursor: 'not-allowed',
            },
            _invalid: {
                borderColor: colors.orange['100'],
            },
        }
    }),
}

//
export const Textarea = defineStyleConfig({
    baseStyle,
    sizes,
    variants,
    defaultProps: {
        size: 'md',
        variant: 'filled',
    },
})
