import qs, { StringifiableRecord } from 'query-string'

export const addQueryParams = (url: string, params?: object) => {
    return qs.stringifyUrl({ url, query: params as StringifiableRecord })
}

// ensure an scheme exists at the start of the url and add one if it doesn't
export const ensureScheme = (url: string, scheme = 'https') => {
    return url.startsWith(`${scheme}://`) ? url : `${scheme}://${url}`
}
